import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src2997050974/src/kinetic-ui/docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "getprofileattributevalues"
    }}>{`getProfileAttributeValues`}</h1>
    <p>{`The `}<inlineCode parentName="p">{`getProfileAttributeValues`}</inlineCode>{` function is similar to the `}<inlineCode parentName="p">{`getProfileAttributeValue`}</inlineCode>{` function except this function's
used for retrieving attributes which can have multiple values.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`import { Utils } from '@kineticdata/bundle-common';

Utils.getProfileAttributeValues(user, 'Color', ['Basic']);
// returns either the Color attribute as an array or ['Basic'] if that attribute is missing or empty.
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      